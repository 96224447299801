// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 参数初始化
export const initParam = (params) => {
    return postRequest('/app/InitParam/initParam', params)
}

// 法律法规初始化
export const initFlfg = (params) => {
    return postRequest('/app/InitParam/initFlfg', params)
}

// lucene初始化
export const initLucene = (params) => {
    return postRequest('/app/InitParam/initLucene', params)
}

// 测试
export const testSendMsg = (params) => {
    return postRequest('/busi/wxUser/send', params)
}

// 微信菜单初始化
export const wxCreateMenu = (params) => {
    return postRequest('/wx/menu/create', params)
}

// imei获取
export const getImei = (params) => {
    return postRequest('/busi/user/getImei', params)
}

// 小程序版本获取
export const getMiniVer = (params) => {
    return postRequest('/app/InitParam/getMiniVer', params)
}

// 小程序版本变更
export const setMiniVer = (params) => {
    return postRequest('/app/InitParam/setMiniVer', params)
}

// 获取工具栏是否用小程序控制位
export const getToolIsMini = (params) => {
    return postRequest('/app/InitParam/getToolIsMini', params)
}

// 设置工具栏是否用小程序控制位
export const setToolIsMini = (params) => {
    return postRequest('/app/InitParam/setToolIsMini', params)
}

// 强制手机号码登录
export const forceLogin = (params) => {
    return postRequest('/app/InitParam/forceLogin', params)
}