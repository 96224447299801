<template>
    <div class="search">
        <Card>
            <Form ref="searchForm" :model="searchForm" class="search-form" >
                <Row>
                    <FormItem class="br">
                        <Button @click="handleInitParam" icon="ios-search">参数初始化</Button>
                        <Button @click="handleInitFlfg" icon="ios-search">法律法规初始化</Button>
                        <Button @click="handleInitLucene" icon="ios-search">Lucene初始化</Button>
                        <Button @click="handleWxCreateMenu" icon="ios-search">微信菜单初始化</Button>
                        <Button @click="handleTest" icon="ios-search">测试</Button>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem class="br">
                        <Input v-model="form.imei" />
                    </FormItem>
                    <FormItem class="br">
                        <Input v-model="form.imei2" />
                    </FormItem>
                    <Button @click="handleImei" icon="ios-search">imei生成</Button>
                </Row>
                <Row>
                    <FormItem label="小程序版本" prop="miniver">
                        <div style="display: flex;">
                            <Input v-model="form.miniver" style="width: 200px;"/>
                            <Button @click="handleMiniVer">变更（需要初始化参数生效）</Button>
                        </div>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="强制登录手机号码" prop="telno">
                        <div style="display: flex;">
                            <Input v-model="form.telno" style="width: 300px;"/>
                            <Button @click="handelForceLogin">提交</Button>
                        </div>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="工具栏小程序控制位" prop="miniver">
                        <div style="display: flex;">
                            <Input v-model="form.toolIsMini" style="width: 400px;"/>
                            <Button @click="handleToolIsMini">变更（需要初始化参数生效）</Button>
                        </div>
                    </FormItem>
                </Row>
           </Form>
        </Card>
    </div>
</template>
<script>
    // 根据你的实际请求api.js位置路径修改
    import {
        initParam,
        initFlfg,
        initLucene,
        wxCreateMenu,
        testSendMsg,
        getImei,
        getMiniVer,
        setMiniVer,
        forceLogin,
        getToolIsMini,
        setToolIsMini,
    } from "@/api/busi/data-manage/system-init.js";
    import {
        getAllDict,
    } from "@/api/index.js";
    export default {
        name: "system-init",
        components: {},
        data() {
            return {
                loading: true, // 表单加载状态
                form: {
                    imei: "",
                    imei2: "",
                    miniver: '',
                    toolIsMini: '',
                },
            };
        },
        methods: {
            handleInitParam() {
                this.$Modal.confirm({
                    title: "确认初始化",
                    // 记得确认修改此处
                    content: "您确认要初始化系统参数?",
                    loading: true,
                    onOk: () => {
                        initParam().then(res => {
                            this.$Modal.remove();
                            this.$Message.success("操作成功");
                        });
                    }
                });
            },
            handleInitFlfg() {
                this.$Modal.confirm({
                    title: "确认初始化",
                    // 记得确认修改此处
                    content: "您确认要初始化法律法规?",
                    loading: true,
                    onOk: () => {
                        initFlfg().then(res => {
                            this.$Modal.remove();
                            this.$Message.success("操作成功");
                        });
                    }
                });
            },
            handleInitLucene() {
                this.$Modal.confirm({
                    title: "确认初始化",
                    // 记得确认修改此处
                    content: "您确认要初始化Lucene?",
                    loading: true,
                    onOk: () => {
                        initLucene().then(res => {
                            this.$Modal.remove();
                            this.$Message.success("操作成功");
                        });
                    }
                });
            },
            handleWxCreateMenu() {
                this.$Modal.confirm({
                    title: "确认初始化",
                    // 记得确认修改此处
                    content: "您确认要初始微信菜单?",
                    loading: true,
                    onOk: () => {
                        wxCreateMenu().then(res => {
                            this.$Modal.remove();
                            this.$Message.success("操作成功");
                        });
                    }
                });
            },
            handleTest() {
                this.$Modal.confirm({
                    title: "测试",
                    // 记得确认修改此处
                    content: "测试?",
                    loading: true,
                    onOk: () => {
                        testSendMsg().then(res => {
                             this.$Modal.remove();
                           this.$Message.success("操作成功");
                        });
                    }
                });
            },
            handleImei() {
                if (this.form.imei == "") {
                    this.$Message.warning("请输入IMEI");
                    return;
                }
                getImei(this.form).then(res => {
                    this.form.imei2 = res.result.enImei;
                    this.$Message.success("操作成功");
                });
            },
            handleMiniVer() {
                if (this.form.miniver == "") {
                    this.$Message.warning("请输入小程序版本");
                    return;
                }
                setMiniVer(this.form).then(res => {
                    this.form.miniver = res.result.miniver;
                    this.$Message.success("操作成功");
                });
            },
            handleToolIsMini() {
                if (this.form.toolIsMini == "") {
                    this.$Message.warning("请输入控制位！");
                    return;
                }
                if (this.form.toolIsMini.length < 15) {
                    this.$Message.warning("工具栏至少15个功能，至少15位！");
                    return;
                }
                setToolIsMini(this.form).then(res => {
                    this.form.toolIsMini = res.result._tool_is_mini;
                    this.$Message.success("操作成功");
                });
            },
            handelForceLogin() {
                if (this.form.telno == "") {
                    this.$Message.warning("请输入手机号码");
                    return;
                }
                forceLogin(this.form).then(res => {
                    this.form.telno = res.result.telno;
                    this.$Message.success("操作成功");
                });
            },
            init() {
                getMiniVer(this.form).then(res => {
                    this.form.miniver = res.result.miniver;
                });
                getToolIsMini(this.form).then(res => {
                    this.form.toolIsMini = res.result._tool_is_mini;
                });
            },
        },
        mounted() {
            this.init();
        },
    };
</script>